import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { WarningAmber, ReportProblem, Info } from '@mui/icons-material'; // Ikony z Material-UI
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';

function HomePage() {
  const [expandedSubject, setExpandedSubject] = useState(null);
  const [planImage, setPlanImage] = useState('');
  const [sessionType, setSessionType] = useState('Zimowy');  // Domyślna wartość
  const [notifications, setNotifications] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [event, setEvent] = useState(null);
  const [timer, setTimer] = useState(null);
  const [weekType, setWeekType] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');




  useEffect(() => {
    axios.get('http://localhost:4000/settings/session')
      .then(response => {
        setSessionType(response.data);
      })
      .catch(error => console.log('Błąd podczas pobierania sesji:', error));

    axios.get('http://localhost:4000/notifications')
      .then(response => setNotifications(response.data))
      .catch(error => console.log(error));
    
    axios.get('http://localhost:4000/subjects')
      .then(response => setSubjects(response.data))
      .catch(error => console.log(error));

    axios.get('http://localhost:4000/events')
      .then(response => {
        if (response.data.length > 0) {
          setEvent(response.data[0]);
          startTimer(response.data[0].date);
        }
      })
      .catch(error => console.log(error));

      setWeekType(isEvenWeek());
      // Pobierz odpowiednie zdjęcie planu w zależności od tygodnia
    if (isEvenWeek() === 'Parzysty') {
      setPlanImage('http://localhost:4000/plan/even');
    } else {
      setPlanImage('http://localhost:4000/plan/odd');
    }
  }, []);

  

  function isEvenWeek() {
    const threeDays = 3 * 24 * 60 * 60 * 1000; // 3 dni w milisekundach
    const timeCount = Date.now() - threeDays; // odejmujemy 3 dni
    const weekDuration = 7 * 24 * 60 * 60 * 1000; // 1 tydzień w milisekundach
    const weekCount = Math.floor(timeCount / weekDuration); // ile tygodni minęło

    return weekCount % 2 === 0 ? "Parzysty" : "Nieparzysty";
  }
  

  const startTimer = (eventDate) => {
    const countDownDate = new Date(eventDate).getTime();
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      if (distance < 0) {
        clearInterval(interval);
        setTimer("Wydarzenie zakończone");
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setTimer(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      }
    }, 1000);
  };

  const scrapeData = () => {
    setShowModal(true);
    const urls = [
        "https://dos.usz.edu.pl/cukrowa/zajecia-cukrowa/",
        "https://dos.usz.edu.pl/cukrowa/zajecia-cukrowa/page/2",
        "https://dos.usz.edu.pl/cukrowa/zajecia-cukrowa/page/3"
    ];

    let foundAnyInfo = false;
    let content = ''; // Variable to hold the modal content

    urls.forEach(url => {
        fetch(`https://cors-anywhere.herokuapp.com/${url}`)
            .then(response => response.text())
            .then(data => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(data, 'text/html');
                const entries = doc.querySelectorAll('h2');

                entries.forEach(entry => {
                    if (entry.textContent.includes('271')) {
                        foundAnyInfo = true;

                        content += `<h2>Tytuł: ${entry.textContent}</h2>`;

                        let postMeta = entry.nextElementSibling;
                        while (postMeta && postMeta.tagName !== 'P') {
                            postMeta = postMeta.nextElementSibling;
                        }

                        content += `<p>${postMeta ? postMeta.textContent : "Brak elementu <p> dla tego tytułu."}</p>`;

                        let postContentInner = entry.nextElementSibling;
                        while (postContentInner && postContentInner.tagName !== 'DIV') {
                            postContentInner = postContentInner.nextElementSibling;
                        }

                        content += `<p>${postContentInner ? postContentInner.querySelector('p').textContent : "Brak elementu <p> wewnątrz <div> dla tego tytułu."}</p>`;
                        content += '<hr>'; // Separator
                    }
                });

                if (!foundAnyInfo) {
                    content = '';
                }

                setModalContent(content);
                 // Show modal
            })
            .catch(error => {
                content += `<p>Błąd podczas pobierania strony: ${url}</p>`;
                setModalContent(content);
                 // Show modal with error
            });
    });
};

 // Funkcja do zamykania modalu
 const closeModal = () => {
  setShowModal(false);
};

  // Funkcja do rozwijania szczegółów przedmiotu
  const toggleSubjectDetails = (index) => {
    setExpandedSubject(expandedSubject === index ? null : index);
  };
  // Funkcja do renderowania ikony w zależności od ważności powiadomienia
  const getIconByImportance = (importance) => {
    switch (importance) {
      case 'high':
        return <WarningAmber className="text-red-600 mr-2" />;
      case 'medium':
        return <ReportProblem className="text-yellow-600 mr-2" />;
      default:
        return <Info className="text-gray-600 mr-2" />;
    }
  };

  // Funkcja do renderowania koloru tła w zależności od ważności powiadomienia
  const getBackgroundColorByImportance = (importance) => {
    switch (importance) {
      case 'high':
        return 'bg-red-100';
      case 'medium':
        return 'bg-yellow-100';
      default:
        return 'bg-gray-100';
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Nagłówek */}
      <header className="bg-gradient-to-r from-blue-600 to-blue-900 text-white py-6 shadow-lg">
        {/* Wrapper div to center content */}
        <div className="container mx-auto flex flex-col items-center">
          <div className="flex items-center justify-center space-x-4">
            <img 
              src="logo512.png" 
              alt="Logo" 
              className="h-12 w-12 hover:scale-110 transition-all duration-300" 
            />
            <h1 className="text-2xl lg:text-4xl font-bold tracking-widest">
              GRUPA 371 – Uniwersytet Szczeciński
            </h1>
          </div>
        </div>

        <nav className="bg-blue-800 mt-6 shadow-md">
          <div className="container mx-auto flex justify-center space-x-8 py-4">
            <Link to="/pliki" className="text-lg text-white hover:text-yellow-300 transition-all duration-300">Pliki</Link>
            <Link
              to="#"
              className="text-lg text-white hover:text-yellow-300 transition-all duration-300"
              onClick={scrapeData} // Call the function on click
            >
              Dziekanat
            </Link>
          </div>
        </nav>
      </header>

      {/* Główna zawartość */}
      <main className="container mx-auto p-6 max-w-6xl space-y-12">

        {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-50" onClick={closeModal}></div>
          <div className="bg-white rounded-lg shadow-lg p-6 z-10 w-11/12 md:w-1/3">
            <h2 className="text-xl font-bold mb-4">Informacje grup</h2>
            <div className="modal-body" dangerouslySetInnerHTML={{ __html: modalContent }} />
            <button
              className="mt-4 bg-blue-600 text-white rounded px-4 py-2 hover:bg-blue-700 transition"
              onClick={closeModal}
            >
              Zamknij
            </button>
          </div>
        </div>
      )}
        
        {/* Powiadomienia */}
        <section>
          <h2 className="text-3xl font-semibold mb-6 text-blue-900">Powiadomienia</h2>
          <div className="space-y-4">
            {notifications.map((notification, index) => (
              <div 
                key={index} 
                className={`p-5 shadow-lg rounded-lg flex items-center ${getBackgroundColorByImportance(notification.importance)} space-x-4`}>
                {getIconByImportance(notification.importance)}
                <div>
                  <h3 className="text-xl font-bold text-blue-800">{notification.title}</h3>
                  <p className="text-sm text-gray-500">{notification.date} {notification.time}</p>
                  <p>{notification.content}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Plan Zajęć */}
        <section>
          <h2 className="text-3xl font-semibold mb-6 text-blue-900">Plan Zajęć – Semestr {sessionType}</h2>
          <div className="w-full max-w-4xl mx-auto">
            {/* Wyświetlenie tygodnia */}
            <section>
          <h3 className="text-2xl font-semibold mb-4 text-blue-900">Aktualny tydzień: {weekType}</h3>
        </section>
          <img src={planImage} alt={`Plan tygodnia ${weekType}`} className="w-full h-auto rounded-lg shadow-lg" />
        </div>
        </section>

        {/* Sekcja Przedmiotów */}
      <section className="mb-12">
        <h2 className="text-3xl font-semibold mb-6 text-blue-900">Przedmioty</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {subjects.map((subject, index) => (
            <div 
              key={index} 
              className="bg-gradient-to-br from-green-100 to-blue-100 p-6 rounded-lg shadow-lg transform transition-transform hover:scale-105 hover:shadow-2xl">
              <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleSubjectDetails(index)}>
                <div>
                  <h3 className="text-xl font-bold text-gray-800">{subject.name}</h3>
                  <p className="text-gray-600">Prowadzący: {subject.teacher}</p>
                </div>
                <ExpandMore className={`text-gray-600 transition-transform transform ${expandedSubject === index ? 'rotate-180' : 'rotate-0'}`} />
              </div>

              {expandedSubject === index && (
                <div className="mt-4">
                  <p className="text-gray-700">{subject.details}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </section>

{/* Sekcja Wydarzenia */}
{event && (
        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-4 text-blue-900">Nadchodzące Wydarzenie</h2>
          <div className="relative overflow-hidden bg-gradient-to-r from-blue-500 to-green-500 rounded-lg p-6 shadow-lg transform transition-transform hover:scale-105 hover:shadow-2xl">
            <div className="animate-slide-in-up">
              <h3 className="text-2xl font-bold text-white mb-2">{event.description}</h3>
              <p className="text-lg text-white mb-4">Data: {event.date}</p>
              <div className="text-lg font-semibold text-white">
                Odliczanie: <span className="text-yellow-300">{timer}</span>
              </div>
            </div>
            {/* Dekoracyjny element z animacją */}
            <div className="absolute -bottom-4 -right-4 w-32 h-32 bg-white opacity-10 rounded-full transform rotate-45 animate-pulse"></div>
          </div>
        </section>
        )}

      </main>

      {/* Stopka */}
      <footer className="bg-blue-700 text-white py-6">
        <div className="container mx-auto text-center">
          <p className="text-sm">Strona zrobiona w celach edukacyjnych dla studentów Uniwersytetu Szczecińskiego</p>
        </div>
      </footer>
    </div>
  );
}

export default HomePage;
