import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Box, Typography, Button, TextField, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function AdminDashboard() {
  const [evenPlan, setEvenPlan] = useState(null);
  const [oddPlan, setOddPlan] = useState(null);
  const [sessionType, setSessionType] = useState('Zimowy');  // Domyślna wartość
  const [notifications, setNotifications] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [events, setEvents] = useState([]);
  const [editingNotification, setEditingNotification] = useState(null);
  const [editingSubject, setEditingSubject] = useState(null);
  const [editingEvent, setEditingEvent] = useState(null);
  const [open, setOpen] = useState(false);
  const [modalContentType, setModalContentType] = useState(null);


  
  // Fields for notifications
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [content, setContent] = useState('');
  const [importance, setImportance] = useState('normal');

  // Fields for subjects
  const [subjectName, setSubjectName] = useState('');
  const [teacher, setTeacher] = useState('');
  const [details, setDetails] = useState('');

  // Fields for events
  const [eventDescription, setEventDescription] = useState('');
  const [eventDate, setEventDate] = useState('');
  const navigate = useNavigate();

  const token = localStorage.getItem('token');

  useEffect(() => {
    const token = localStorage.getItem('token');
    
    if (!token) {
      // No token, redirect to login
      navigate('/admin');
    } else {
      // Verify token with backend
      axios.get('http://localhost:4000/verify-token', {
        headers: { 'Authorization': token }
      })
      .then(() => {
        // Token is valid, allow access to dashboard
      })
      .catch(() => {
        // Token is invalid or expired, redirect to login
        localStorage.removeItem('token'); // Clear the expired/invalid token
        navigate('/admin');
      });
    }
  }, [navigate]);
  // Pobierz sesję z serwera
  useEffect(() => {
    axios.get('http://localhost:4000/settings/session')
      .then(response => {
        setSessionType(response.data);
      })
      .catch(error => console.log('Błąd podczas pobierania sesji:', error));
  }, []);

  // Zapisz sesję na serwerze
  const handleSaveSession = () => {
    axios.put('http://localhost:4000/settings/session', { sessionType }, {
      headers: { Authorization: localStorage.getItem('token') }
    })
    .then(() => {
      alert('Sesja zaktualizowana');
    })
    .catch(() => {
      alert('Błąd podczas aktualizacji sesji');
    });
  };

  // Fetch notifications, subjects, and events
  useEffect(() => {
    axios.get('http://localhost:4000/notifications')
      .then(response => setNotifications(response.data))
      .catch(error => console.log(error));

    axios.get('http://localhost:4000/subjects')
      .then(response => setSubjects(response.data))
      .catch(error => console.log(error));

    axios.get('http://localhost:4000/events')
      .then(response => setEvents(response.data))
      .catch(error => console.log(error));
  }, []);

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === 'evenPlan') {
      setEvenPlan(files[0]);
    } else if (name === 'oddPlan') {
      setOddPlan(files[0]);
    }
  };

  const handleUpload = () => {
    const formData = new FormData();
    formData.append('evenPlan', evenPlan);
    formData.append('oddPlan', oddPlan);

    axios.post('http://localhost:4000/upload/plans', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem('token'),
      },
    })
    .then(response => {
      alert('Plany zostały przesłane');
    })
    .catch(() => {
      alert('Błąd podczas przesyłania plików');
    });
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
    setModalContentType(null);
  };

  // Edycja przedmiotu
const handleEditSubject = (id) => {
  const subject = subjects.find(s => s._id === id);
  setEditingSubject(id);
  setModalContentType('subject');
  setSubjectName(subject.name);
  setTeacher(subject.teacher);
  setDetails(subject.details);
  setOpen(true);
};

// Edycja wydarzenia
const handleEditEvent = (id) => {
  const event = events.find(e => e._id === id);
  setEditingEvent(id);
  setModalContentType('event');
  setEventDescription(event.description);
  setEventDate(event.date);
  setOpen(true);
};


  const resetForm = () => {
    setEditingNotification(null);
    setEditingSubject(null);
    setEditingEvent(null);
    setTitle('');
    setDate('');
    setTime('');
    setContent('');
    setImportance('normal');
    setSubjectName('');
    setTeacher('');
    setDetails('');
    setEventDescription('');
    setEventDate('');
  };

  // Delete notification
  const handleDeleteNotification = (id) => {
    if (window.confirm('Czy na pewno chcesz usunąć to powiadomienie?')) {
      axios.delete(`http://localhost:4000/notifications/${id}`, { headers: { Authorization: token } })
        .then(() => {
          alert('Powiadomienie usunięte');
          setNotifications(notifications.filter(n => n._id !== id));
        })
        .catch(() => alert('Błąd podczas usuwania powiadomienia'));
    }
  };

  // Delete subject
  const handleDeleteSubject = (id) => {
    if (window.confirm('Czy na pewno chcesz usunąć ten przedmiot?')) {
      axios.delete(`http://localhost:4000/subjects/${id}`, { headers: { Authorization: token } })
        .then(() => {
          alert('Przedmiot usunięty');
          setSubjects(subjects.filter(s => s._id !== id));
        })
        .catch(() => alert('Błąd podczas usuwania przedmiotu'));
    }
  };

  // Delete event
  const handleDeleteEvent = (id) => {
    if (window.confirm('Czy na pewno chcesz usunąć to wydarzenie?')) {
      axios.delete(`http://localhost:4000/events/${id}`, { headers: { Authorization: token } })
        .then(() => {
          alert('Wydarzenie usunięte');
          setEvents(events.filter(e => e._id !== id));
        })
        .catch(() => alert('Błąd podczas usuwania wydarzenia'));
    }
  };

  // Editing and adding logic (similar to before)
  const handleEditNotification = (id) => {
    const notification = notifications.find(n => n._id === id);
    setEditingNotification(id);
    setModalContentType('notification');
    setTitle(notification.title);
    setDate(notification.date);
    setTime(notification.time);
    setContent(notification.content);
    setImportance(notification.importance);
    setOpen(true);
  };

  const handleSaveNotification = () => {
    if (editingNotification) {
      axios.put(`http://localhost:4000/notifications/${editingNotification}`, {
        title, date, time, content, importance
      }, { headers: { Authorization: token } })
        .then(() => {
          alert('Powiadomienie zaktualizowane');
          handleClose();
          window.location.reload();
        })
        .catch(() => alert('Błąd podczas aktualizacji powiadomienia'));
    } else {
      axios.post('http://localhost:4000/notifications', {
        title, date, time, content, importance
      }, { headers: { Authorization: token } })
        .then(() => {
          alert('Powiadomienie dodane');
          handleClose();
          window.location.reload();
        })
        .catch(() => alert('Błąd podczas dodawania powiadomienia'));
    }
  };

  const handleAddNotification = () => {
    setModalContentType('notification');
    resetForm();
    setOpen(true);
  };

  const handleAddSubject = () => {
    setModalContentType('subject');
    resetForm();
    setOpen(true);
  };

  const handleSaveSubject = () => {
    if (editingSubject) {
      axios.put(`http://localhost:4000/subjects/${editingSubject}`, {
        name: subjectName, teacher, details
      }, { headers: { Authorization: token } })
        .then(() => {
          alert('Przedmiot zaktualizowany');
          handleClose();
          window.location.reload();
        })
        .catch(() => alert('Błąd podczas aktualizacji przedmiotu'));
    } else {
      axios.post('http://localhost:4000/subjects', {
        name: subjectName, teacher, details
      }, { headers: { Authorization: token } })
        .then(() => {
          alert('Przedmiot dodany');
          handleClose();
          window.location.reload();
        })
        .catch(() => alert('Błąd podczas dodawania przedmiotu'));
    }
  };

  const handleAddEvent = () => {
    setModalContentType('event');
    resetForm();
    setOpen(true);
  };

  const handleSaveEvent = () => {
    if (editingEvent) {
      axios.put(`http://localhost:4000/events/${editingEvent}`, {
        description: eventDescription, date: eventDate
      }, { headers: { Authorization: token } })
        .then(() => {
          alert('Wydarzenie zaktualizowane');
          handleClose();
          window.location.reload();
        })
        .catch(() => alert('Błąd podczas aktualizacji wydarzenia'));
    } else {
      axios.post('http://localhost:4000/events', {
        description: eventDescription, date: eventDate
      }, { headers: { Authorization: token } })
        .then(() => {
          alert('Wydarzenie dodane');
          handleClose();
          window.location.reload();
        })
        .catch(() => alert('Błąd podczas dodawania wydarzenia'));
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container mx-auto p-4">
        <h2 className="text-3xl font-bold mb-8">Panel Administratora</h2>
        

        {/* Powiadomienia */}
        <section className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">Powiadomienia</h3>
          <Button variant="contained" color="primary" onClick={handleAddNotification}>
            Dodaj Powiadomienie
          </Button>
          <div className="grid grid-cols-1 gap-4 mt-4">
            {notifications.map(notification => (
              <div key={notification._id} className="p-4 bg-gray-100 shadow-md rounded-lg">
                <h4 className={`text-xl font-bold ${notification.importance === 'high' ? 'text-red-600' : notification.importance === 'medium' ? 'text-yellow-600' : 'text-gray-600'}`}>
                  {notification.importance === 'high' && '⚠️'} {notification.title}
                </h4>
                <p>{notification.date} {notification.time}</p>
                <p>{notification.content}</p>
                <div className="flex gap-4">
                  <button onClick={() => handleEditNotification(notification._id)} className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 mt-2">
                    Edytuj
                  </button>
                  <button onClick={() => handleDeleteNotification(notification._id)} className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 mt-2">
                    Usuń
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Przedmioty */}
        <section className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">Przedmioty</h3>
          <Button variant="contained" color="primary" onClick={handleAddSubject}>
            Dodaj Przedmiot
          </Button>
          <div className="grid grid-cols-1 gap-4 mt-4">
            {subjects.map(subject => (
              <div key={subject._id} className="p-4 bg-gray-100 shadow-md rounded-lg">
                <h4 className="text-xl font-bold">{subject.name}</h4>
                <p>Prowadzący: {subject.teacher}</p>
                <p>{subject.details}</p>
                <div className="flex gap-4">
                  <button onClick={() => handleEditSubject(subject._id)} className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 mt-2">
                    Edytuj
                  </button>
                  <button onClick={() => handleDeleteSubject(subject._id)} className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 mt-2">
                    Usuń
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Wydarzenia */}
        <section className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">Wydarzenia</h3>
          <Button variant="contained" color="primary" onClick={handleAddEvent}>
            Dodaj Wydarzenie
          </Button>
          <div className="grid grid-cols-1 gap-4 mt-4">
            {events.map(event => (
              <div key={event._id} className="p-4 bg-gray-100 shadow-md rounded-lg">
                <h4 className="text-xl font-bold">{event.description}</h4>
                <p>Data: {event.date}</p>
                <div className="flex gap-4">
                  <button onClick={() => handleEditEvent(event._id)} className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 mt-2">
                    Edytuj
                  </button>
                  <button onClick={() => handleDeleteEvent(event._id)} className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 mt-2">
                    Usuń
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>
        {/* Sekcja zarządzania sesją */}
        <section className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">Zarządzanie Sesją</h3>
          <TextField
            select
            label="Sesja"
            value={sessionType}
            onChange={(e) => setSessionType(e.target.value)}
            fullWidth
            variant="outlined"
            className="mb-4"
          >
            <MenuItem value="Zimowy">Zimowy</MenuItem>
            <MenuItem value="Letni">Letni</MenuItem>
          </TextField>
          <Button variant="contained" color="primary" onClick={handleSaveSession}>
            Zapisz Sesję
          </Button>
        </section>

        <div className="container mx-auto p-4">
      <h2 className="text-3xl font-bold mb-4">Zarządzanie Planami Zajęć</h2>
      <div className="mb-4">
        <label className="block font-semibold mb-2">Plan dla tygodnia parzystego:</label>
        <input type="file" name="evenPlan" accept="image/*" onChange={handleFileChange} />
      </div>
      <div className="mb-4">
        <label className="block font-semibold mb-2">Plan dla tygodnia nieparzystego:</label>
        <input type="file" name="oddPlan" accept="image/*" onChange={handleFileChange} />
      </div>
      <button onClick={handleUpload} className="px-4 py-2 bg-blue-600 text-white rounded-lg">Prześlij plany</button>
    </div>

        {/* Modal - Editing */}
        <Modal open={open} onClose={handleClose}>
          <Box 
            sx={{ 
              position: 'absolute', 
              top: '50%', 
              left: '50%', 
              transform: 'translate(-50%, -50%)', 
              width: 400, 
              bgcolor: 'background.paper', 
              borderRadius: 2, 
              boxShadow: 24, 
              p: 4 
            }}>
            {modalContentType === 'notification' && (
              <>
                <Typography variant="h6" gutterBottom>
                  {editingNotification ? 'Edytuj Powiadomienie' : 'Dodaj Powiadomienie'}
                </Typography>
                <TextField label="Tytuł" variant="outlined" fullWidth value={title} onChange={(e) => setTitle(e.target.value)} className="mb-4" />
                <TextField label="Data" type="date" variant="outlined" fullWidth value={date} onChange={(e) => setDate(e.target.value)} className="mb-4" />
                <TextField label="Czas" type="time" variant="outlined" fullWidth value={time} onChange={(e) => setTime(e.target.value)} className="mb-4" />
                <TextField label="Treść" variant="outlined" fullWidth multiline value={content} onChange={(e) => setContent(e.target.value)} className="mb-4" />
                <TextField select label="Priorytet" variant="outlined" fullWidth value={importance} onChange={(e) => setImportance(e.target.value)} className="mb-4">
                  <MenuItem value="normal">Zwykłe</MenuItem>
                  <MenuItem value="medium">Ważne</MenuItem>
                  <MenuItem value="high">Bardzo ważne</MenuItem>
                </TextField>
                <Button variant="contained" color="primary" onClick={handleSaveNotification}>
                  {editingNotification ? 'Zapisz' : 'Dodaj'}
                </Button>
              </>
            )}

            {modalContentType === 'subject' && (
              <>
                <Typography variant="h6" gutterBottom>
                  {editingSubject ? 'Edytuj Przedmiot' : 'Dodaj Przedmiot'}
                </Typography>
                <TextField label="Nazwa przedmiotu" variant="outlined" fullWidth value={subjectName} onChange={(e) => setSubjectName(e.target.value)} className="mb-4" />
                <TextField label="Prowadzący" variant="outlined" fullWidth value={teacher} onChange={(e) => setTeacher(e.target.value)} className="mb-4" />
                <TextField label="Szczegóły" variant="outlined" fullWidth multiline value={details} onChange={(e) => setDetails(e.target.value)} className="mb-4" />
                <Button variant="contained" color="primary" onClick={handleSaveSubject}>
                  {editingSubject ? 'Zapisz' : 'Dodaj'}
                </Button>
              </>
            )}

            {modalContentType === 'event' && (
              <>
                <Typography variant="h6" gutterBottom>
                  {editingEvent ? 'Edytuj Wydarzenie' : 'Dodaj Wydarzenie'}
                </Typography>
                <TextField label="Opis wydarzenia" variant="outlined" fullWidth value={eventDescription} onChange={(e) => setEventDescription(e.target.value)} className="mb-4" />
                <TextField label="Data" type="date" variant="outlined" fullWidth value={eventDate} onChange={(e) => setEventDate(e.target.value)} className="mb-4" />
                <Button variant="contained" color="primary" onClick={handleSaveEvent}>
                  {editingEvent ? 'Zapisz' : 'Dodaj'}
                </Button>
              </>
            )}
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default AdminDashboard;
